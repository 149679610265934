<template>
  <div class="library">
    <TopBlock
      :title="'Библиотека'"
      :description="'Информация о заболеваниях, современных подходах к терапии и многое другое. Созданы для того, чтобы помочь Вам в вашей повседневной профессиональной деятельности'"
      :images="[
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/library-page_mob_1.jpg`,
          media: '(max-width: 767px)',
        },
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/library-page_tablet_1.jpg`,
          media: '(max-width: 1220px)',
        },
        {
          src: `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/library-page_1.jpg`,
          media: '(max-width: 99999px)',
        },
      ]"
    />
    <Breadcrumbs :pages="[{ name: 'Библиотека' }]" />
    <div class="library__list library-list">
      <div class="content">
        <div class="library-list__row">
          <div class="library-block">
            <div class="library-block__image-container">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/library1.jpg`"
                class="library-block__image"
              />
            </div>
            <div class="library-block__text-container">
              <div class="library-block__title">Публикации</div>
              <div class="library-block__description">
                Материалы, интервью, клинические случаи
              </div>
              <div
                @click="toLibs({ name: 'TextMaterials' }, 'Публикации')"
                class="library-block__button button button_pink button_small"
              >
                Подробнее
                <span class="ml-2">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.00012L6 6.00012L1 11.0001"
                      stroke="white"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="library-block">
            <div class="library-block__image-container">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/library2.jpg`"
                class="library-block__image"
              />
            </div>
            <div class="library-block__text-container">
              <div class="library-block__title">Видеоматериалы</div>
              <div class="library-block__description">
                Онлайн-конференции, вебинары
              </div>
              <div
                @click="toLibs({ name: 'VideoMaterials' }, 'Видеоматериалы')"
                class="library-block__button button button_pink button_small"
              >
                Подробнее
                <span class="ml-2">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.00012L6 6.00012L1 11.0001"
                      stroke="white"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="library-block">
            <div class="library-block__image-container">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/library3.jpg`"
                class="library-block__image"
              />
            </div>
            <div class="library-block__text-container">
              <div class="library-block__title">Подкасты</div>
              <div class="library-block__description">
                Аудио и видеоподкасты для врачей
              </div>
              <div
                @click="toLibs({ name: 'Podcasts' }, 'Подкасты')"
                :to="{ name: 'Podcasts' }"
                class="library-block__button button button_pink button_small"
              >
                Подробнее
                <span class="ml-2">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.00012L6 6.00012L1 11.0001"
                      stroke="white"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import TopBlock from "../../components/pageComponents/TopBlock.vue";
export default {
  metaInfo: {
    title: "Библиотека",
  },
  name: "Library",
  components: { TopBlock, Breadcrumbs },
  methods: {
    toLibs(to, text) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "library page info materials click", {
          "library page info materials click": {
            "material name": text,
             ...this.$root.ymFields
          },
        });
      }
      vm.$router.push(to).catch(() => {});
    },
  },
};
</script>
    
<style lang="scss" scoped>
.library-list {
  padding-top: 65px;

  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      row-gap: 24px;
    }
  }
}

.library-block {
  border: 1px solid #d2d2d2;

  &__image-container {
    width: 100%;
    height: 214px;

    @media screen and (max-width: 767px) {
      height: 125px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__text-container {
    padding: 24px;
  }

  &__title {
    margin-bottom: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__description {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #656969;
  }

  &__button {
    width: 122px;
    height: 28px;
  }
}
</style>